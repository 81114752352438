import React from 'react'
import { Link } from 'gatsby'

import { colors, margins, fontSizes, breakpoint, alpha } from '../../cms/style'
// import stringEnter from '../../utils/stringEnter.util'
import { briefs } from '../../cms/data/reklama-dla-biznesu/data'

import BriefNazwa from '../../assets/briefs/ReklamaDlaBiznesu.pl-Nazwa-Brief.pdf'
import BriefSMM from '../../assets/briefs/ReklamaDlaBiznesu.pl-Social-Media-Brief.pdf'
// import BriefWWW from '../../assets/briefs/ReklamaDlaBiznesu.pl-Strony-WWW-Brief.pdf'
import BriefMarki from '../../assets/briefs/ReklamaDlaBiznesu.pl-Tworzenie-Marek_Brief.pdf'
// import BriefVideo from '../../assets/briefs/ReklamaDlaBiznesu.pl_Video_Brief.pdf'
import BriefMarketing from '../../assets/briefs/ReklamaDlaBiznesu.pl_Marketing-Outsourcing_Brief.pdf'

import fileIocn from '../../assets/icons-svg/file.svg'

const briefsData = [
  { pdf: BriefNazwa, name: 'Naming' },
  { pdf: BriefSMM, name: 'Social Media Marketing' },
  // { pdf: BriefWWW, name: 'Strony WWW' },
  { pdf: BriefMarki, name: 'Tworzenie marek' },
  // { pdf: BriefVideo, name: 'Video' },
  { pdf: BriefMarketing, name: 'Marketing Outsourcing' },
]
const BriefItem = ({ data }) => (
  <a css={styles.servicesItem} href={data.pdf} target="_blank" rel="noopener">
    <h5>{data.name}</h5>

    <span>
      Pobierz PDF <img src={fileIocn} alt="pobierz" />
    </span>
  </a>
)

export default function S2() {
  return (
    <section css={styles.mainSection}>
      <div css={styles.info}>
        <h5 css={styles.header}>{briefs.header}</h5>
      </div>

      <div css={styles.servicesParent}>
        {briefsData.map((el, index) => <BriefItem key={index} data={el} />)}
      </div>
    </section>
  )
}

const styles = {
  mainSection: {
    padding: margins.sectionsX.xl,
    marginBottom: 40,

    [breakpoint.lg]: {
      padding: margins.sectionsX.lg,
    },
    [breakpoint.md]: {
      padding: margins.sectionsX.md,
    },
    [breakpoint.sm]: {
      padding: margins.sectionsX.sm,
      paddingRight: 48,
    },
    [breakpoint.xs]: {
      padding: margins.sectionsX.xs,
      paddingRight: 48,
    },
  },

  info: {
    width: "100%",

    [breakpoint.md]: {
      width: "100%",
    },
    [breakpoint.sm]: {
      width: "100%",
    },
  },

  header: {
    color: colors.headers.grey,
    fontSize: fontSizes.fz3.xl,
    letterSpacing: "-0.6px",
    marginBottom: 24,
    textAlign:'center !important',

    [breakpoint.lg]: {
      fontSize: fontSizes.fz3.lg,
    },
    [breakpoint.md]: {
      fontSize: fontSizes.fz3.md,
    },
    [breakpoint.sm]: {
      fontSize: fontSizes.fz3.sm,
    },
    [breakpoint.xs]: {
      fontSize: fontSizes.fz3.xs,
    },
  },

  servicesParent: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },

  servicesItem: {
    width: "calc(25% - 24px)",
    minHeight: 160,
    minWidth: 200,
    padding: "24px",
    margin: "12px 0",
    border: `2px solid ${colors.other.lightBlack.concat(alpha[70])}`,
    borderRadius: 16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    cursor: "pointer",
    textDecoration: "none",

    h5: {
      fontSize: 20,
      fontWeight: 700,
      color: "#000000",
    },

    span: {
      color: "#000",
      letterSpacing: "4px",
      textTransform: "uppercase",

      borderBottom: "3px solid transparent",
      transition: "all 0.25s linear",
      position: "relative",

      display: 'flex',
      alignItems: 'center',
      img: {
        width: '1.4rem',
        marginTop: '-.3rem',
        marginLeft: '.5rem',
      }
    },

    "span:before": {
      content: '" "',
      display: "block",
      width: "80%",
      height: 3,
      backgroundColor: "#C7000AE6",
      position: "absolute",
      left: 0,
      bottom: "-8px",
      transformOrigin: "left",
      transform: "scale(0)",
      transition: "0.25s linear",
    },

    "&:hover span:before": {
      transform: "scale(1)",
    },
    "&:hover": {
      borderColor: "#C7000AE6",
    },
    [breakpoint.xs]: {
      width: "100%",
    },
  },
}
