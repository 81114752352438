// export const section2 = {
//          paragraphs: {
//            paragraph: "Społecznościówki to podstawowy punkt styku marki z klientem.",
//            imgUrl: "",
//            paragraph2: "Jeśli chcesz się udzielać w Social Media to z pewnością dostrzegassz ich potencjał. <br> W <b>RDB Socials</b> wiemy co może ci w tym pomóc.",
//            paragraph3: "Poznaj pakiety przygotowane po to byś nie musiał się martwić o twoją obecność w social mediach.",
//            paragraph4: "Warto wybrać mądrze i zwiększać zasięgi na konkretnych kanałach. Daj szansę swojej marce!",
//          },
//        }

export const section2 = {
    introTxt: "Rozwijaj komunikację firmy dużo szybciej dzięki pakietom RDB socials!",
    postintroTxt: 'Wieloletnie doświadczenie w branży marketingowej przy współpracy z kluczowymi markami pozwala nam tworzyć zautomatyzowane kampanie nakierowane na pozyskiwanie klientów dla firm. Specjalizujemy się w profesjonalnym budowaniu spójnego wizerunku marki, przygotowywaniu kreacji wizualnych i kampanii reklamowych. Dysponujemy narzędziami, które przyczyniają się do szybkiego rozwoju \ni wzrostu zysków! Oferujemy nieszablonowe rozwiązania łączące w sobie różne technologie produkcyjne.\n Wybierz pakiet dostosowany do Twoich potrzeb i zwiększ widoczność swojej marki!',
    afterHeadTxt: 'Dzięki pakietom RDB socials osiągniesz cele zasięgowe, a Twoja marka stanie sie widoczna!\nUtrzymywanie stałej komunikacji z potencjalnym klientem to podstawa rozwoju\nTwojego biznesu!',
    afterTxt: 'Masz wybrany pakiet, ale chcesz poszerzyć go o dodatkowe elementy? Napisz do nas!',
    packages: [{
            title: 'Start',
            main: 'Pakiet START na usystematyzowanie komunikacji i stałą obecność marki w social media. W ramach pakietu konsultujemy',
            mainMore: ' stan obecny i dostosowujemy profil do obecnych wymogów platform. Zadbamy o spójność komunikatów oraz umożliwimy pierwszy kontakt poprzez messenger. To dzięki tym działaniom zwiększy się świadomość marki w oczach klienta. Co miesiąc dostarczamy szczegółowy raport naszych działań uwzględniający ilościowe tabele wzrostu. Co obejmuje pakiet Social Media Start Pack?',
            mainList: [
                'Analiza marki pod kątem stanu obecnego - przygotowanie planu komunikacji',
                'Audyt i dostosowanie kanałów',
                'Planowanie komunikatów na social media',
            ],
            additionalList: [
                'Prowadzenie profilu na portalu Facebook - 2 posty w tygodniu',
                'Przygotowywanie grafik do postów',
                'Prowadzenie wizytówki Google',
                'Tworzenie raportów miesięcznych i analiza wyników',
            ],
            price: '1500',
            pln: 'PLN',
            time: '/miesiąc',
            btnOff: 'Czytaj więcej...',
            btnOn: 'Czytaj mniej...',
            color: '#4C4C4C !important',
            textcolor: 'black',
            headtxtSize: '42px',
            colorHover: '#4C4C4C',
            background: '#fff',
            buttontxt: 'ZAMÓW',
            priceBackground: 'linear-gradient(to right, transparent, rgba(0,0,0,.2))',
        },
        {
            title: 'Business',
            main: 'Pakiet BUSINESS to możliwość prowadzenia komunikacji na 2 wybranych platformach społecznościowych.',
            mainMore: ' W ramach tego pakietu konsultujemy cele marki i ustalamy na ich podstawie plan kwartalny półroczny i roczny. Na bazie celów wyznaczamy harmonogram prac i przygotowujemy długofalowy plan komunikacji marki w social media. Pakiet oferuje również możliwość zastosowania reklam w celu uzyskania większego zainteresowania kanałem komunikacji i zdobyciu stałego audytorium potencjalnych klientów.',
            mainList: [
                'Planowanie komunikatów na Social media',
                'Prowadzenie profilu na portalu Facebook - 2 posty w tygodniu',
                'Prowadzenie profilu na Instagram lub Linkedin - 1 post w tygodniu',
            ],
            additionalList: [
                'Przygotowywanie grafik do postów',
                'Prowadzenie wizytówki Google',
                'Facebook Ads',
                'Tworzenie raportów miesięcznych',
            ],
            price: '2500',
            pln: 'PLN',
            time: '/miesiąc',
            btnOff: 'Czytaj więcej...',
            btnOn: 'Czytaj mniej...',
            color: '#00B000 !important',
            textcolor: 'black',
            headtxtSize: '42px',
            colorHover: '#00B000',
            background: '#fff',
            buttontxt: 'ZAMÓW',
            priceBackground: 'linear-gradient(to right, transparent, rgba(0,176,0,.2))',
        },
        {
            title: 'Premium',
            main: 'Pakiet PREMIUM oferuje pełne spektrum rozwiązań dla marki w zakresie mediów społecznościowych. W ramach pakietu firma',
            mainMore: ' zyskuje opiekuna zarządzającego codzienną praca specjalistów. To gwarancja szybkich reakcji na wiadomości, udział w dyskusjach oraz przygotowanie szkutecznej kampanii reklamowej marki. To najszybszy i najbardziej skuteczny sposób na budowanie zasięgu kanałów komunikacji.',
            mainList: [
                'Prowadzenie profilu na portalu Facebook - 5 postów w tygodniu',
                'Instagram lub Linkedin - 3 posty w tygodniu',
                'Pozostałe social media (Instagram, Linkedin, Pinterest) - 1 post',
            ],
            additionalList: [
                'Przygotowywanie grafik do postów',
                'Prowadzenie wizytówki Google firmy',
                'Facebook Ads',
                'Tworzenie raportów miesięcznych',
                'Spotkania zespołu kreatywnego w trakcie przygotowania kampanii marketingowej na social media',
                'Tworzenie aktywacji niestandardowych, które zwiększą zaangażowanie użytkowników w social media',
                'Tworzenie planu komunikacji produktów i marki',
                'Prowadzenie działań wspierających dialog społeczny',
            ],
            price: '3500',
            pln: 'PLN',
            time: '/miesiąc',
            btnOff: 'Czytaj więcej...',
            btnOn: 'Czytaj mniej...',
            color: '#fff !important',
            textcolor: 'black',
            headtxtSize: '42px',
            colorHover: '#DB0000',
            background: 'linear-gradient(to bottom right, #DB0000, #970000)',
            buttontxt: 'ZAMÓW',
            priceBackground: 'linear-gradient(to right, transparent, rgba(255,255,255,.4))',
        },
        {
            title: 'Marketing Outsourcing',
            main: ' Razem z grupą specjalistów pomożemy dostosować strategię do nowej rzeczywistości, poprowadzimy osobisty plan rozwoju',
            mainMore: 'firmy z nakierowaniem na wzrost i sprzedaż. Wykreujemy wizerunek Twojej marki oraz uwypuklimy jej działania na tle konkurencji. Jesteśmy Twoim działem marketingu, ale rozliczamy się za przepracowane roboczogodziny niezależnie od tego jakich specjalistów w danym momencie potrzebuje Twój marketing.',
            mainList: ['Elastyczne, indywidualne podejście dostosowane do potrzeb marki',
                'Poprowadzimy za Ciebie strony firmowe, fanpage, pozycjonowanie',
                'Projekty graficzne, wideo, reklamy, banery itd.'
            ],
            additionalList: [],
            contact: 'indywidualna oferta',
            pln: '',
            time: '',
            btnOff: 'Czytaj więcej...',
            btnOn: 'Czytaj mniej...',
            color: '#0076B0 !important',
            background: '#fff',
            buttontxt: 'KONTAKT',
            textcolor: 'black',
            headtxtSize: '15px !important',
            colorHover: '#0076B0',
            priceBackground: 'linear-gradient(to right, transparent, rgba(0,118,176,.2))',
        },
    ],
 
}