import React, { useState } from 'react'
import { colors, margins, fontSizes, breakpoint } from '../../cms/style'


const formInitialValues = {
  date: '',
  name: '',
  companyName: '',
  tel: '',
  email: '',
  topic: '',
  hoursAmount: ''
}


const Input = ({ isError, ...otherProps }) => (
  <input
    css={[styles.inputCss, isError ? { borderBottom: '1px solid #C7000A' } : {}]}
    {...otherProps}
  />
)

export default function S3Formularz() {
  const [formData, setFormData] = useState(formInitialValues)
  const [err, setErr] = useState({})

  const handleChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value })
  const handleSubmit = () => {
    const errors = {}
    inputChecking()
    function inputChecking() {
      if (formData.name.length < 3) errors['name'] = true
      if (formData.date.length < 3) errors['date'] = true
      if (formData.companyName.length < 3) errors['companyName'] = true
      if (formData.tel.length < 5) errors['tel'] = true
      if (formData.email.length < 3) errors['email'] = true
      if (formData.topic.length < 3) errors['topic'] = true
      if (formData.hoursAmount <= 0) errors['hoursAmount'] = true
      setErr(errors)
    }
    if (Object.keys(errors).length === 0) console.log(formData)
  }

  return (
    <section css={styles.mainSection}>
      <h3 css={styles.header}>Umów się na konsultację</h3>
      <Input
        type="text"
        name="name"
        placeholder="Imię"
        onChange={handleChange}
        value={formData.name}
        isError={err["name"]}
      />
      <Input
        type="email"
        name="email"
        placeholder="Email"
        onChange={handleChange}
        value={formData.email}
        isError={err["email"]}
      />
      <Input
        type="text"
        name="companyName"
        placeholder="Nazwa firmy"
        onChange={handleChange}
        value={formData.companyName}
        isError={err["companyName"]}
      />
      <Input
        type="tel"
        name="tel"
        placeholder="Telefon"
        onChange={handleChange}
        value={formData.tel}
        isError={err["tel"]}
      />
      <Input
        type="date"
        name="date"
        onChange={handleChange}
        value={formData.date}
        isError={err["date"]}
        css={{ fontFamily: "'Montserrat', sans-serif" }}
      />
      <Input
        type="number"
        name="hoursAmount"
        placeholder="Ilość godzin"
        onChange={handleChange}
        value={formData.hoursAmount}
        isError={err["hoursAmount"]}
      />
      <textarea
        name="topic"
        css={[
          styles.textArea,
          err["topic"] ? { borderBottom: "1px solid #C7000A" } : {},
        ]}
        placeholder="Temat spotkania..."
        onChange={handleChange}
        value={formData.topic}
      />
      <button css={styles.btn} onClick={handleSubmit}>
        <span>Umów konsultację</span>
      </button>
    </section>
  )
}

const styles = {
  mainSection: {
    paddingTop:'50px !important',
    padding: margins.sectionsX.xl,
    //backgroundColor: colors.other.lightgrey,

    [breakpoint.lg]: {
      padding: margins.sectionsX.lg,
    },
    [breakpoint.md]: {
      padding: margins.sectionsX.md,
    },
    [breakpoint.sm]: {
      padding: margins.sectionsX.sm,
      paddingRight: 48,
    },
    [breakpoint.xs]: {
      padding: margins.sectionsX.xs,
      paddingRight: 48,
    },
  },

  header: {
    color: colors.headers.lightBlack,
    fontSize: fontSizes.fz3.xl,
    letterSpacing: "-0.6px",
    marginBottom: 24,
    textAlign: 'center',

    [breakpoint.lg]: {
      fontSize: fontSizes.fz3.lg,
    },
    [breakpoint.md]: {
      fontSize: fontSizes.fz3.md,
    },
    [breakpoint.sm]: {
      fontSize: fontSizes.fz3.sm,
    },
    [breakpoint.xs]: {
      fontSize: fontSizes.fz3.xs,
    },

  },

  inputCss: {
    border: "none",
    borderBottom: "1px solid #eee",
    borderRadius: 8,
    padding: ".5rem",
    width: "calc(50% - 1rem)",
    margin: "1rem",
    minHeight: 48,
    fontSize: 16,

    boxShadow:
      "inset 1px 2px 4px rgba(0, 0, 0, 0.01), 0px 0px 8px rgba(0, 0, 0, 0.2)",

    "&:focus": {
      outline: "none",
      borderBottom: "1px solid rgb(0, 0, 0, 0.4)",
    },

    "&:nth-child(2n)": {
      marginLeft: 0,
    },

    "&:nth-child(2n-1)": {
      marginRight: 0,
    },

    [breakpoint.lg]: {
      fontSize: fontSizes.fz5.lg,
    },
    [breakpoint.md]: {
      fontSize: fontSizes.fz5.md,
    },
    [breakpoint.sm]: {
      fontSize: fontSizes.fz5.sm,
    },
    [breakpoint.xs]: {
      fontSize: fontSizes.fz5.xs,
    },
  },

  textArea: {
    border: "none",
    borderBottom: "1px solid #eee",
    borderRadius: 8,
    padding: ".5rem",
    width: "100%",
    margin: "1rem 0",
    minHeight: 96,
    fontSize: 16,
    fontFamily: "'Montserrat', sans-serif",
    boxShadow: "inset 1px 2px 4px rgba(0, 0, 0, 0.01), 0px 0px 8px rgba(0, 0, 0, 0.2)",
    "&:focus": {
      outline: "none",
      borderBottom: "1px solid rgb(0, 0, 0, 0.4)",
    },
  },

  btn: {
    display: "block",
    margin: "16px auto 0",
    backgroundColor: "transparent",
    borderRadius:'15px',
    padding: ".5em 1.5em",
    cursor: "pointer",
    fontSize: 20,
    textTransform: "uppercase",
    border: "2px solid black",
    overflow: "hidden",
    position: "relative",
    transition: "all 550ms cubic-bezier(0.19, 1, 0.22, 1)",

    span: { zIndex: 20 },

    "&:hover": {
      transition: "all 2550ms cubic-bezier(0.19, 1, 0.22, 1)",
      background: '#DB0000',
      transition: '0.5s',
      color: '#fff',
    },

    "&:focus": {
      outline: "none",
    },

    [breakpoint.sm]: {
      fontSize: 16,
      borderWidth: 1,
    },
  },
}