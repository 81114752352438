export const section2 = {
         headParagraphs: [
           "Reklamę Dla Biznesu tworzą ludzie, specjaliści w konkretnych obszarach marketingu. To właśnie dzięki nim agencja obsługuje klientów na bardzo wysokim poziomie.",
           "Zobacz jakie zespoły pracują w Reklamie Dla Biznesu",
         ],
         services: [
           {
             header: "PROJECT MANAGER",
             text: "Twój łącznik z naszymi zespołami.",
             imgId: "team1",
           },
           {
             header: "TEAM GOOGLE",
             text: "Silni sojusznicy w rywalizacji w sieci.",
             imgId: "team2",
           },
           {
             header: "TEAM VIDEO",
             text: "Zespół, który swoją kreatywność przenosi na ekran.",
             imgId: "team3",
           },
           {
             header: "SOCIAL MEDIA TEAM",
             text: "Dbamy o zasięgi Twojej marki i aktywność jej społeczności.",
             imgId: "team4",
           },
           {
             header: "GRAPHIC TEAM",
             text:
               "Zbudujemy Twój znak od pierwszej kreski do ostatniego szlifu.",
             imgId: "team5",
           },
           {
             header: "COPYWRITING",
             text: "Treści trafiające do Twoich klientów.",
             imgId: "team6",
           },
         ],
       }

export const briefs = {
  header: 'Briefy do pobrania',

}